import React from "react"
import { Form, Button, Header, Dropdown } from "semantic-ui-react"
import { connect } from "react-redux"

const countries = [
  { eng: "Afghanistan", countryCode: "af" },
  { eng: "Aland Islands", countryCode: "ax" },
  { eng: "Albania", countryCode: "al" },
  { eng: "Algeria", countryCode: "dz" },
  { eng: "American Samoa", countryCode: "as" },
  { eng: "Andorra", countryCode: "ad" },
  { eng: "Angola", countryCode: "ao" },
  { eng: "Anguilla", countryCode: "ai" },
  { eng: "Antigua", countryCode: "ag" },
  { eng: "Argentina", countryCode: "ar" },
  { eng: "Armenia", countryCode: "am" },
  { eng: "Aruba", countryCode: "aw" },
  { eng: "Australia", countryCode: "au" },
  { eng: "Austria", countryCode: "at" },
  { eng: "Azerbaijan", countryCode: "az" },
  { eng: "Bahamas", countryCode: "bs" },
  { eng: "Bahrain", countryCode: "bh" },
  { eng: "Bangladesh", countryCode: "bd" },
  { eng: "Barbados", countryCode: "bb" },
  { eng: "Belarus", countryCode: "by" },
  { eng: "Belgium", countryCode: "be" },
  { eng: "Belize", countryCode: "bz" },
  { eng: "Benin", countryCode: "bj" },
  { eng: "Bermuda", countryCode: "bm" },
  { eng: "Bhutan", countryCode: "bt" },
  { eng: "Bolivia", countryCode: "bo" },
  { eng: "Bosnia", countryCode: "ba" },
  { eng: "Botswana", countryCode: "bw" },
  { eng: "Bouvet Island", countryCode: "bv" },
  { eng: "Brazil", countryCode: "br" },
  { eng: "British Virgin Islands", countryCode: "vg" },
  { eng: "Brunei", countryCode: "bn" },
  { eng: "Bulgaria", countryCode: "bg" },
  { eng: "Burkina Faso", countryCode: "bf" },
  { eng: "Burma", countryCode: "mm", alias: "Myanmar" },
  { eng: "Burundi", countryCode: "bi" },
  { eng: "Caicos Islands", countryCode: "tc" },
  { eng: "Cambodia", countryCode: "kh" },
  { eng: "Cameroon", countryCode: "cm" },
  { eng: "Canada", countryCode: "ca" },
  { eng: "Cape Verde", countryCode: "cv" },
  { eng: "Cayman Islands", countryCode: "ky" },
  { eng: "Central African Republic", countryCode: "cf" },
  { eng: "Chad", countryCode: "td" },
  { eng: "Chile", countryCode: "cl" },
  { eng: "China", countryCode: "cn" },
  { eng: "Christmas Island", countryCode: "cx" },
  { eng: "Cocos Islands", countryCode: "cc" },
  { eng: "Colombia", countryCode: "co" },
  { eng: "Comoros", countryCode: "km" },
  { eng: "Congo", countryCode: "cd" },
  { eng: "Congo Brazzaville", countryCode: "cg" },
  { eng: "Cook Islands", countryCode: "ck" },
  { eng: "Costa Rica", countryCode: "cr" },
  { eng: "Cote Divoire", countryCode: "ci" },
  { eng: "Croatia", countryCode: "hr" },
  { eng: "Cuba", countryCode: "cu" },
  { eng: "Cyprus", countryCode: "cy" },
  { eng: "Czech Republic", countryCode: "cz" },
  { eng: "Denmark", countryCode: "dk" },
  { eng: "Djibouti", countryCode: "dj" },
  { eng: "Dominica", countryCode: "dm" },
  { eng: "Dominican Republic", countryCode: "do" },
  { eng: "Ecuador", countryCode: "ec" },
  { eng: "Egypt", countryCode: "eg" },
  { eng: "El Salvador", countryCode: "sv" },
  { eng: "England", countryCode: "gb eng" },
  { eng: "Equatorial Guinea", countryCode: "gq" },
  { eng: "Eritrea", countryCode: "er" },
  { eng: "Estonia", countryCode: "ee" },
  { eng: "Ethiopia", countryCode: "et" },
  { eng: "Europeanunion", countryCode: "eu" },
  { eng: "Falkland Islands", countryCode: "fk" },
  { eng: "Faroe Islands", countryCode: "fo" },
  { eng: "Fiji", countryCode: "fj" },
  { eng: "Finland", countryCode: "fi" },
  { eng: "France", countryCode: "fr" },
  { eng: "French Guiana", countryCode: "gf" },
  { eng: "French Polynesia", countryCode: "pf" },
  { eng: "French Territories", countryCode: "tf" },
  { eng: "Gabon", countryCode: "ga" },
  { eng: "Gambia", countryCode: "gm" },
  { eng: "Georgia", countryCode: "ge" },
  { eng: "Germany", countryCode: "de" },
  { eng: "Ghana", countryCode: "gh" },
  { eng: "Gibraltar", countryCode: "gi" },
  { eng: "Greece", countryCode: "gr" },
  { eng: "Greenland", countryCode: "gl" },
  { eng: "Grenada", countryCode: "gd" },
  { eng: "Guadeloupe", countryCode: "gp" },
  { eng: "Guam", countryCode: "gu" },
  { eng: "Guatemala", countryCode: "gt" },
  { eng: "Guinea", countryCode: "gn" },
  { eng: "Guinea-Bissau", countryCode: "gw" },
  { eng: "Guyana", countryCode: "gy" },
  { eng: "Haiti", countryCode: "ht" },
  { eng: "Heard Island", countryCode: "hm" },
  { eng: "Honduras", countryCode: "hn" },
  { eng: "Hong Kong", countryCode: "hk" },
  { eng: "Hungary", countryCode: "hu" },
  { eng: "Iceland", countryCode: "is" },
  { eng: "India", countryCode: "in" },
  { eng: "Indian Ocean Territory", countryCode: "io" },
  { eng: "Indonesia", countryCode: "id" },
  { eng: "Iran", countryCode: "ir" },
  { eng: "Iraq", countryCode: "iq" },
  { eng: "Ireland", countryCode: "ie" },
  { eng: "Israel", countryCode: "il" },
  { eng: "Italy", countryCode: "it" },
  { eng: "Jamaica", countryCode: "jm" },
  { eng: "Jan Mayen", countryCode: "sj", alias: "Svalbard" },
  { eng: "Japan", countryCode: "jp" },
  { eng: "Jordan", countryCode: "jo" },
  { eng: "Kazakhstan", countryCode: "kz" },
  { eng: "Kenya", countryCode: "ke" },
  { eng: "Kiribati", countryCode: "ki" },
  { eng: "Kuwait", countryCode: "kw" },
  { eng: "Kyrgyzstan", countryCode: "kg" },
  { eng: "Laos", countryCode: "la" },
  { eng: "Latvia", countryCode: "lv" },
  { eng: "Lebanon", countryCode: "lb" },
  { eng: "Lesotho", countryCode: "ls" },
  { eng: "Liberia", countryCode: "lr" },
  { eng: "Libya", countryCode: "ly" },
  { eng: "Liechtenstein", countryCode: "li" },
  { eng: "Lithuania", countryCode: "lt" },
  { eng: "Luxembourg", countryCode: "lu" },
  { eng: "Macau", countryCode: "mo" },
  { eng: "Macedonia", countryCode: "mk" },
  { eng: "Madagascar", countryCode: "mg" },
  { eng: "Malawi", countryCode: "mw" },
  { eng: "Malaysia", countryCode: "my" },
  { eng: "Maldives", countryCode: "mv" },
  { eng: "Mali", countryCode: "ml" },
  { eng: "Malta", countryCode: "mt" },
  { eng: "Marshall Islands", countryCode: "mh" },
  { eng: "Martinique", countryCode: "mq" },
  { eng: "Mauritania", countryCode: "mr" },
  { eng: "Mauritius", countryCode: "mu" },
  { eng: "Mayotte", countryCode: "yt" },
  { eng: "Mexico", countryCode: "mx" },
  { eng: "Micronesia", countryCode: "fm" },
  { eng: "Moldova", countryCode: "md" },
  { eng: "Monaco", countryCode: "mc" },
  { eng: "Mongolia", countryCode: "mn" },
  { eng: "Montenegro", countryCode: "me" },
  { eng: "Montserrat", countryCode: "ms" },
  { eng: "Morocco", countryCode: "ma" },
  { eng: "Mozambique", countryCode: "mz" },
  { eng: "Namibia", countryCode: "na" },
  { eng: "Nauru", countryCode: "nr" },
  { eng: "Nepal", countryCode: "np" },
  { eng: "Netherlands", countryCode: "nl" },
  { eng: "Netherlandsantilles", countryCode: "an" },
  { eng: "New Caledonia", countryCode: "nc" },
  { eng: "New Guinea", countryCode: "pg" },
  { eng: "New Zealand", countryCode: "nz" },
  { eng: "Nicaragua", countryCode: "ni" },
  { eng: "Niger", countryCode: "ne" },
  { eng: "Nigeria", countryCode: "ng" },
  { eng: "Niue", countryCode: "nu" },
  { eng: "Norfolk Island", countryCode: "nf" },
  { eng: "North Korea", countryCode: "kp" },
  { eng: "Northern Mariana Islands", countryCode: "mp" },
  { eng: "Norway", countryCode: "no" },
  { eng: "Oman", countryCode: "om" },
  { eng: "Pakistan", countryCode: "pk" },
  { eng: "Palau", countryCode: "pw" },
  { eng: "Palestine", countryCode: "ps" },
  { eng: "Panama", countryCode: "pa" },
  { eng: "Paraguay", countryCode: "py" },
  { eng: "Peru", countryCode: "pe" },
  { eng: "Philippines", countryCode: "ph" },
  { eng: "Pitcairn Islands", countryCode: "pn" },
  { eng: "Poland", countryCode: "pl" },
  { eng: "Portugal", countryCode: "pt" },
  { eng: "Puerto Rico", countryCode: "pr" },
  { eng: "Qatar", countryCode: "qa" },
  { eng: "Reunion", countryCode: "re" },
  { eng: "Romania", countryCode: "ro" },
  { eng: "Russia", countryCode: "ru" },
  { eng: "Rwanda", countryCode: "rw" },
  { eng: "Saint Helena", countryCode: "sh" },
  { eng: "Saint Kitts and Nevis", countryCode: "kn" },
  { eng: "Saint Lucia", countryCode: "lc" },
  { eng: "Saint Pierre", countryCode: "pm" },
  { eng: "Saint Vincent", countryCode: "vc" },
  { eng: "Samoa", countryCode: "ws" },
  { eng: "San Marino", countryCode: "sm" },
  { eng: "Sandwich Islands", countryCode: "gs" },
  { eng: "Sao Tome", countryCode: "st" },
  { eng: "Saudi Arabia", countryCode: "sa" },
  { eng: "Scotland", countryCode: "gb sct" },
  { eng: "Senegal", countryCode: "sn" },
  { eng: "Serbia", countryCode: "rs" },
  { eng: "Seychelles", countryCode: "sc" },
  { eng: "Sierra Leone", countryCode: "sl" },
  { eng: "Singapore", countryCode: "sg" },
  { eng: "Slovakia", countryCode: "sk" },
  { eng: "Slovenia", countryCode: "si" },
  { eng: "Solomon Islands", countryCode: "sb" },
  { eng: "Somalia", countryCode: "so" },
  { eng: "South Africa", countryCode: "za" },
  { eng: "South Korea", countryCode: "kr" },
  { eng: "Spain", countryCode: "es" },
  { eng: "Sri Lanka", countryCode: "lk" },
  { eng: "Sudan", countryCode: "sd" },
  { eng: "Suriname", countryCode: "sr" },
  { eng: "Swaziland", countryCode: "sz" },
  { eng: "Sweden", countryCode: "se" },
  { eng: "Switzerland", countryCode: "ch" },
  { eng: "Syria", countryCode: "sy" },
  { eng: "Taiwan", countryCode: "tw" },
  { eng: "Tajikistan", countryCode: "tj" },
  { eng: "Tanzania", countryCode: "tz" },
  { eng: "Thailand", countryCode: "th" },
  { eng: "Timorleste", countryCode: "tl" },
  { eng: "Togo", countryCode: "tg" },
  { eng: "Tokelau", countryCode: "tk" },
  { eng: "Tonga", countryCode: "to" },
  { eng: "Trinidad", countryCode: "tt" },
  { eng: "Tunisia", countryCode: "tn" },
  { eng: "Turkey", countryCode: "tr" },
  { eng: "Turkmenistan", countryCode: "tm" },
  { eng: "Tuvalu", countryCode: "tv" },
  { eng: "U.A.E.", countryCode: "ae", alias: "United Arab Emirates" },
  { eng: "Uganda", countryCode: "ug" },
  { eng: "Ukraine", countryCode: "ua" },
  { eng: "United Kingdom", countryCode: "gb", alias: "uk" },
  { eng: "United States", countryCode: "us", alias: "America" },
  { eng: "Uruguay", countryCode: "uy" },
  { eng: "US Minor Islands", countryCode: "um" },
  { eng: "US Virgin Islands", countryCode: "vi" },
  { eng: "Uzbekistan", countryCode: "uz" },
  { eng: "Vanuatu", countryCode: "vu" },
  { eng: "Vatican City", countryCode: "va" },
  { eng: "Venezuela", countryCode: "ve" },
  { eng: "Vietnam", countryCode: "vn" },
  { eng: "Wales", countryCode: "gb wls" },
  { eng: "Wallis and Futuna", countryCode: "wf" },
  { eng: "Western Sahara", countryCode: "eh" },
  { eng: "Yemen", countryCode: "ye" },
  { eng: "Zambia", countryCode: "zm" },
  { eng: "Zimbabwe", countryCode: "zw" },
]

const paises = [
  "Afganistán",
  "Islas Aland",
  "Albania",
  "Argelia",
  "Samoa Americana",
  "Andorra",
  "Angola",
  "Anguila",
  "Antigua",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaiyán",
  "Bahamas",
  "Bahréin",
  "Bangladesh",
  "Barbados",
  "Bielorrusia",
  "Bélgica",
  "Belice",
  "Benin",
  "Bermudas",
  "Bután ",
  "Bolivia",
  "Bosnia",
  "Botswana",
  "Isla Bouvet",
  "Brasil",
  "Islas Vírgenes Británicas",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Birmania",
  "Burundi",
  "Islas Caicos",
  "Camboya",
  "Camerún",
  "Canadá",
  "Cabo Verde",
  "Islas Caimán",
  "República Centroafricana",
  "Chad",
  "Chile",
  "China",
  "Isla de Navidad",
  "Islas Cocos",
  "Colombia",
  "Comoras",
  "Congo",
  "Congo Brazzaville",
  "Islas Cook",
  "Costa Rica",
  "Cote Divoire",
  "Croacia",
  "Cuba",
  "Chipre",
  "República Checa",
  "Dinamarca",
  "Djibouti",
  "Dominica",
  "República Dominicana",
  "Ecuador",
  "Egipto",
  "El Salvador",
  "Inglaterra",
  "Guinea Ecuatorial",
  "Eritrea",
  "Estonia",
  "Etiopía",
  "Unión Europea",
  "Islas Malvinas",
  "Islas Feroe",
  "Fiji",
  "Finlandia",
  "Francia",
  "Guayana francesa",
  "Polinesia francesa",
  "Territorios franceses",
  "Gabón",
  "Gambia",
  "Georgia",
  "Alemania",
  "Ghana",
  "Gibraltar",
  "Grecia",
  "Groenlandia",
  "Granada",
  "Guadalupe",
  "Guam",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haití",
  "Isla Heard",
  "Honduras",
  "Hong Kong",
  "Hungría",
  "Islandia",
  "India",
  "Territorio del Océano Índico",
  "Indonesia",
  "Irán",
  "Irak",
  "Irlanda",
  "Israel",
  "Italia",
  "Jamaica",
  "Jan Mayen",
  "Japón",
  "Jordania",
  "Kazajstán",
  "Kenia",
  "Kiribati",
  "Kuwait",
  "Kirguistán",
  "Laos",
  "Letonia",
  "Líbano",
  "Lesoto",
  "Liberia",
  "Libia",
  "Liechtenstein",
  "Lituania",
  "Luxemburgo",
  "Macao",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malasia",
  "Maldivas",
  "Malí",
  "Malta",
  "Islas Marshall",
  "Martinica",
  "Mauritania",
  "Mauricio",
  "Mayotte",
  "México",
  "Micronesia",
  "Moldavia",
  "Mónaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Marruecos",
  "Mozambique",
  "Namibia",
  "Nauru",
  "Nepal",
  "Países Bajos",
  "Netherlandsantilles",
  "Nueva Caledonia",
  "Nueva Guinea",
  "Nueva Zelanda",
  "Nicaragua",
  "Níger",
  "Nigeria",
  "Niue",
  "Isla Norfolk",
  "Corea del Norte",
  "Islas Marianas del Norte",
  "Noruega",
  "Omán",
  "Pakistán",
  "Palau",
  "Palestina",
  "Panamá",
  "Paraguay",
  "Perú",
  "Filipinas",
  "Islas Pitcairn",
  "Polonia",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunión",
  "Rumanía",
  "Rusia",
  "Ruanda",
  "Santa Elena",
  "Saint Kitts y Nevis",
  "Santa Lucía",
  "San Pedro",
  "San Vicente",
  "Samoa",
  "San Marino",
  "Islas Sandwich",
  "Santo Tomé",
  "Arabia Saudita",
  "Escocia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leona",
  "Singapur",
  "Eslovaquia",
  "Eslovenia",
  "Islas Salomón",
  "Somalia",
  "Sudáfrica",
  "Corea del Sur",
  "España",
  "Sri Lanka",
  "Sudán",
  "Surinam",
  "Swazilandia",
  "Suecia",
  "Suiza",
  "Siria ",
  "Taiwán",
  "Tayikistán",
  "Tanzania",
  "Tailandia",
  "Timorleste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad",
  "Túnez",
  "Turquía",
  "Turkmenistán",
  "Tuvalu",
  "Emiratos Árabes Unidos",
  "Uganda",
  "Ucrania",
  "Reino Unido",
  "Estados Unidos",
  "Uruguay",
  "Islas Menores de EE. UU.",
  "Islas Vírgenes de EE. UU.",
  "Uzbekistán",
  "Vanuatu",
  "Ciudad del Vaticano",
  "Venezuela",
  "Vietnam",
  "Gales",
  "Wallis y Futuna",
  "Sahara Occidental",
  "Yemen",
  "Zambia",
  "Zimbabwe",
]

// console.log(paises.length === countries.length)

const ctryArr = countries.map(x => {
  return { flag: x.countryCode, key: x.eng, value: x.eng, text: x.eng }
})

const paisArr = paises.map((x, i) => {
  return { flag: countries[i].countryCode, key: x, value: x, text: x }
})

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class FormEx extends React.Component {
  state = { ctry: "", err: "", success: false }

  handleChange = (e, { value }) => {
    this.setState(prevState => ({
      ...prevState,
      ctry: value,
    }))
  }

  handleSubmit = e => {
    console.log("submitting")
    const { first, last, message, email, country } = e.target.elements
    const { ctry } = this.state
    const formPkg = {
      first: first.value,
      last: last.value,
      message: message.value,
      email: email.value,
      country: country.value,
    }

    console.log(formPkg)

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...formPkg }),
    })
      .then(res => {
        console.log("response", res)
        this.setState(prevState => ({
          ...prevState,
          success: true,
          err: false,
        }))
      })
      .catch(error =>
        this.setState(prevState => ({
          ...prevState,
          success: false,
          err: error,
        }))
      )

    e.preventDefault()
  }

  render() {
    const { lang } = this.props
    const { ctry, success, err } = this.state
    console.log(this.state)

    return (
      <>
        {success ? (
          <Header size="medium">
            {lang === "english"
              ? "Thank you for reaching out to Global Connect! We'll be in contact with you ASAP!"
              : "¡Gracias por comunicarse con Global Connect! ¡Nos pondremos en contacto contigo lo antes posible!"}
          </Header>
        ) : (
          <Form
            id="contact"
            name="contact"
            data-netlify="true"
            onSubmit={this.handleSubmit}
            style={{
              maxWidth: "800px",
              margin: "auto",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <input type="hidden" name="form-name" value="contact" />
            <input type="hidden" name="country" value={ctry} />
            <Form.Group widths="equal">
              <Form.Field
                id="form-input-control-first-name"
                control="input"
                label={
                  lang === "english"
                    ? "First Name (required)"
                    : "Nombre (requerido)"
                }
                // placeholder={lang === "english" ? "ex. Joe" : "ej. José"}
                name="first"
                required
                style={{ maxWidth: "300px" }}
              />
              <Form.Field
                id="form-input-control-last-name"
                control="input"
                label={lang === "english" ? "Last Name" : "Apellido"}
                // placeholder={
                //   lang === "english" ? "ex. Olson" : "ej. Hernández Flores"
                // }
                name="last"
                style={{ maxWidth: "300px" }}
              />
            </Form.Group>
            <Form.Field
              id="form-textarea-control-opinion"
              control="textarea"
              label={lang === "english" ? "Message" : "Mensaje"}
              // placeholder={
              //   lang === "english"
              //     ? "ex. I had a problem with"
              //     : "ej. Yo tenia un problema con..."
              // }
              name="message"
              style={{ maxWidth: "800px" }}
            />
            <Form.Field>
              <label>
                {lang === "english" ? "Country (required)" : "País (requerido)"}
              </label>
              <Dropdown
                // name="country"
                style={{ maxWidth: "300px" }}
                value={ctry}
                // placeholder={
                //   lang === "english"
                //     ? "Your country required"
                //     : "Elige país (requerido)"
                // }
                fluid
                search
                selection
                onChange={this.handleChange}
                options={lang === "english" ? ctryArr : paisArr}
              />
            </Form.Field>
            <Form.Field
              id="form-input-control-error-email"
              control="input"
              type="email"
              label={
                lang === "english"
                  ? "Email (required)"
                  : "Correo Electrónico (requerido)"
              }
              // placeholder={
              //   lang === "english"
              //     ? "ex. jolson@gmail.com"
              //     : "ej. josehern@gmail.com"
              // }
              name="email"
              required
              style={{ maxWidth: "400px" }}
            />
            <div>
              {err !== "" ? (
                <Header size="medium">
                  {lang === "english"
                    ? `Error: ${err} We're sorry! Please try again later!`
                    : `Error: ${err} ¡Lo sentimos! ¡Por favor, inténtelo de nuevo más tarde!`}
                </Header>
              ) : (
                <></>
              )}
            </div>
            <Form.Field
              id="form-button-control-public"
              control={Button}
              content={lang === "english" ? "Submit" : "Enviar"}
              type="submit"
              style={{
                maxWidth: "400px",
                backgroundColor: "#74005F",
                color: "white",
              }}
            />
          </Form>
        )}
      </>
    )
  }
}

export default connect(
  state => ({
    lang: state.lang,
  }),
  null
)(FormEx)
