import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Form from "../components/contact-form"
import { Grid, Header } from "semantic-ui-react"
import { connect } from "react-redux"

const Contact = ({ lang }) => {
  return (
    <Layout>
      <SEO title="Contact" />
      <Header
        size="huge"
        style={{ textAlign: "center", marginTop: "3rem", marginBottom: "3rem" }}
      >
        {lang === "english" ? "Contact Sales" : "Contactar a Ventas"}
      </Header>
      <Grid>
        <Grid.Row style={{}}>
          <Grid.Column style={{ textAlign: "center" }}>
            {/* <p
              style={{
                marginTop: "3rem",
                marginBottom: "3rem",
                fontSize: "1.2rem",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {lang === "english"
                ? "To connect with our sales team, please submit the following form"
                : "Para ponerse en contacto con ventas, complete el siguiente formulario"}
            </p> */}
            <Form />
            <p
              style={{
                marginTop: "3rem",
                marginBottom: "0rem",
                fontSize: "1rem",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {lang === "english"
                ? "For technical support please visit our "
                : " Para soporte técnico por favor visita nuestra "}
              <Link to="/support/">
                {lang === "english" ? "support page" : "pagina de soporte"}
              </Link>
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Layout>
  )
}

export default connect(
  state => ({
    lang: state.lang,
  }),
  null
)(Contact)
